/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	background: url(../img/img-1.png) no-repeat center left;
	margin-bottom: 0px!important;
	visibility: hidden;
	&.slick-initialized {
		visibility: visible;
	}
	.slick-slide {
		display: flex;
		height: 850px;
		flex-wrap: wrap;
		justify-content: flex-end;
		overflow: hidden;
		position: relative;
		outline: none;
		.slider__block {
			width: 400px;
			position: absolute;
			left: 50%;
			top: 50%;
			text-align: left;
			color: #414141;
			padding-right: 15px;
			margin-left: -570px;
			transform: translateY(-100%);
			.slider__block--title {
				font-size: 44px;
				text-transform: uppercase;
				line-height: 1.2;
			}
		}
		.slider__img {
			height: 850px;
			position: absolute;
			left: 50%;
			right: 0;
			margin-left: -170px;
			&:after {
				content: '';
				background-color: rgba(0,0,0,0.2);
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
			img {
				display: block;
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center center;
			}
		}
	}
	.slick-arrow {
		width: 48px;
		height: 90px;
		transition: 300ms;
		z-index: 1;
		&:before {
			display: none;
		}
		&:after {
			content: '';
			background: url(../img/prev.png) no-repeat center center;
			background-size: contain;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		&.slick-prev {
			left: 44%;
		}
		&.slick-next {
			right: 50px;
			&:after {
				transform: rotate(-180deg);
			}
		}
		&:hover {
			opacity: 0.7;
		}
	}
	.slick-dots {
		width: auto;
		right: auto;
		left: 50%;
		bottom: 80px;
		margin-left: -570px;
		li {
			display: block;
			width: auto;
			height: auto;
			margin: 10px 0;
			button {
				height: 40px;
				width: 3px;
				background-color: #c9c9c9;
				border-radius: 0;
				border: none;
				padding: 0;
				transition: 300ms;
				&:before {
					display: none;
				}
				&:hover {
					background-color: #d7b56d;
				}
			}
			&.slick-active {
				button {
					background-color: #d7b56d;
				}
			}
		}
	}
	@media screen and (max-width: 1680px) {
		background-size: 40%;
	}
	@media screen and (max-width: 1440px) {
		.slick-slide {
			height: 700px;
			.slider__img {
				height: 100%;
			}
		}
	}
	@media screen and (max-width: 1199px) {
		background: none;
		.slick-slide {
			height: 500px;
			.slider__img {
				width: 100%;
				height: 100%;
				left: 0;
				position: relative;
				margin: 0;
				&:after {
					background: rgba(0,0,0,0.5);
				}
			}
			.slider__block {
				width: auto;
				left: 70px;
				right: 70px;
				color: #fff;
				padding: 0;
				margin: 0;
				transform: translateY(-50%);
				z-index: 1;
				.slider__block--title {
					font-size: 36px;
				}
			}
		}
		.slick-dots {
			left: 10px;
			right: 10px;
			bottom: 25px;
			margin: 0;
			li {
				@include inline-block;
				margin: 0 5px;
				button {
					height: 2px;
					width: 35px;
				}
			}
		}
		.slick-arrow {
			width: 30px;
			height: 60px;
			&.slick-prev {
				left: 15px;
			}
			&.slick-next {
				right: 15px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.slick-slide {
			height: 400px;
			.slider__block .slider__block--title {
				font-size: 30px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.slick-slide {
			height: 300px;
			.slider__block {
				left: 50px;
				right: 50px;
				.slider__block--title {
					font-size: 24px;
				}
			}
		}
		.slick-dots {
			bottom: 10px;
		}
		.slick-arrow {
			width: 25px;
			height: 50px;
			&.slick-prev {
				left: 10px;
			}
			&.slick-next {
				right: 10px;
			}
		}
	}
	@media screen and (max-width: 680px) {
		.slick-slide {
			.slider__block .slider__block--title {
				font-size: 20px;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.slick-slide {
			height: 250px;
			.slider__block .slider__block--title {
				font-size: 18px;
			}
		}
	}
}
/* main slider */

/* main about */
.about {
	.container {
		position: relative;
	}
	.about__col {
		width: 690px;
		margin-bottom: 35px;
		img {
			display: block;
			width: 100%;
		}
	}
	.about__block {
		width: 525px;
		background-color: #fff;
		position: relative;
		padding: 40px 0 0 40px;
		position: absolute;
		left: 505px;
		bottom: 90px;
		h1 {
			text-align: left;
			position: absolute;
			top: 0;
			left: 220px;
			margin: 0;
			transform: translateY(-100%);
		}
	}
	@media screen and (max-width: 1199px) {
		.about__col {
			width: 65%;
		}
		.about__block {
			left: auto;
			right: 0;
			bottom: 50px;
		}
	}
	@media screen and (max-width: 991px) {
		.container {
			display: flex;
			flex-direction: column;
		}
		.about__col {
			width: 100%;
			order: 2;
			margin-bottom: 0;
		}
		.about__block {
			width: 100%;
			position: relative;
			padding: 0;
			bottom: 0;
			order: 1;
			h1 {
				position: relative;
				left: 0;
				transform: translateY(0);
				margin-bottom: 15px;
				br {
					display: none;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.about__block {
			h1 {
				text-align: center;
			}
		}
	}
}
/* main about */

/* main services */
.services {
	.container {
		display: flex;
		flex-wrap: wrap;
		padding-top: 0px;
		justify-content: space-between;	
		&:after, &:before {
			display: none;
		}
	}
	h1, h2 {
		width: 100%;
	}
	.services__col {
		display: inline-flex;
		min-height: 250px;
		width: calc(50% - 30px);
		flex-direction: column;
		padding: 30px 45px 30px 25px;
		border: 1px solid #d7b56d;
		position: relative;
		margin-bottom: 30px;
		h5 {
			min-height: 38px;
			text-transform: uppercase;
			padding-bottom: 10px;
			margin: 0;
			transition: 300ms;
		}
		.icon {
			width: 70px;
			height: 70px;
			position: absolute;
			right: 20px;
			bottom: 20px;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center center;
			}
		}
		&:hover {
			text-decoration: none;
			color: #414141;
			h5 {
				color: #d7b56d;
			}
		}
	}
	.more {
		margin: 15px auto 0;
	}
	@media screen and (max-width: 1199px) {
		.services__col {
			width: calc(50% - 15px);
			.icon {
				width: 50px;
				height: 50px;
				right: 5px;
				bottom: 10px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.services__col {
			padding: 20px 45px 30px 15px;
		}
		.more {
			margin-top: 0px;
			margin-bottom: 15px;
		}
	}
	@media screen and (max-width: 768px) {
		.services__col {
			width: calc(50% - 10px);
			h5 {
				color: #d7b56d;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.services__col {
			min-height: 0px;
			width: 100%;
			h5 {
				min-height: 0px;
			}
		}
	}
}
/* main services */

/* main advantages */
.advantages {
	display: flex;
	flex-wrap: wrap;
	h1 {
		margin-bottom: 30px;
	}
	h2 {
		margin-bottom: 35px;
	}
	h3 {
		font-size: 30px;
		margin: 0 0 25px;
	}
	.advantages__col {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 50%;
		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
			object-position: center center;
		}
		.block {
			width: 570px;
			padding-top: 15px;
			padding-bottom: 15px;
		}
		&.bg {
			background: url(../img/img-22.png) no-repeat center left 12%;
			background-size: contain;
		}
		.advantages__col--cts {
			@include ul-default;
			display: flex;
			flex-wrap: wrap;
			li {
				width: 50%;
				padding-right: 15px;
			}
		}
	}
	&.left {
		.advantages__col:nth-of-type(2) {
			padding: 10px 0 10px 40px;
			h2, h1 {
				text-align: left;
			}
		}
	}
	&.small--img {
		.advantages__col {
			width: 40%;
			&:nth-of-type(1) {
				width: 60%;
				align-items: flex-end;
			}
			.block {
				width: 710px;
			}
		}
		&.right {
			.advantages__col {
				&:nth-of-type(1) {
					padding-right: 45px;
				}
			}
		}
		&.left {
			.advantages__col {
				&:nth-of-type(1) {
					width: 40%;
				}
				&:nth-of-type(2) {
					width: 60%;
					padding-left: 70px;
				}
			}
		}
	}
	@media screen and (max-width: 1440px) {
		&.small--img {
			.advantages__col {
				.block {
					width: 100%;
					padding: 30px;
				}
			}
			&.left .advantages__col:nth-of-type(2) {
				padding: 0;
			}
			&.right .advantages__col:nth-of-type(1) {
				padding: 0;
			}
		}
		
	}
	@media screen and (max-width: 1199px) {
		.advantages__col {
			.block {
				width: 100%;
				padding: 10px 20px;
			}
		}
		&.left .advantages__col:nth-of-type(2) {
			padding-left: 0px;
		}
		&.left.small--img {
			.advantages__col img {
				object-position: center right;
			}
		}
		h3 {
			font-size: 26px;
		}
	}
	@media screen and (max-width: 991px) {
		h3 {
			font-size: 22px;
		}
		.advantages__col {
			.advantages__col--cts {
				li {
					width: 100%;
					padding: 0;
					margin: 5px 0;
				}
			}
		}
		&.small--img {
			margin-bottom: 20px;
		}
	}
	@media screen and (max-width: 768px) {
		padding: 0 15px;
		h2, h1 {
			margin-bottom: 20px;
		}
		h3 {
			font-size: 20px;
			margin-bottom: 15px;
		}
		.advantages__col {
			width: 100%;
			img {
				height: auto;
			}
			.block {
				padding: 0;
			}
			&.apricot {
				padding: 20px!important;
			}
		}
		&.left .advantages__col {
			&:nth-of-type(1) {
				order: 2;
			}
			&:nth-of-type(2) {
				padding: 0;
				order: 1;
			}
		}
		&.right .advantages__col:nth-of-type(1) {
			margin-bottom: 20px;
		}
		&.small--img {
			.advantages__col {
				width: 100%!important;
				&:nth-of-type(1) {
					width: 100%;
				}
				&:nth-of-type(2) {
					width: 100%;
				}
				.block {
					padding: 0;
				}
			}
		}
	}
}
/* main advantages */

/* main partners */
.partners {
	.partners__slider {
		.slick-slide {
			display: flex;
            flex-direction: column;
			align-items: center;
            text-align: center;
			outline: none;
            padding: 10px;
			img {
				display: table;
				max-height: 100%;
				max-width: 100%;
				object-fit: contain;
				object-position: center center;
				margin: 0 auto;
			}
            h5 {
                font-family: $bold;
                font-size: 16px;
            }
            p {
                font-size: 14px;
                margin: 0;
            }
		}
	}
}
.arrow {
	padding: 0 30px;
	.slick-arrow {
		width: 25px;
		height: 46px;
		transition: 300ms;
		z-index: 1;
		&:before {
			display: none;
		}
		&:after {
			content: '';
			background: url(../img/prevG.png) no-repeat center center;
			background-size: contain;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
		}
		&.slick-next {
			right: 0;
			&:after {
				transform: rotate(-180deg);
			}
		}
		&.slick-prev {
			left: 0;
		}
		&:hover {
			opacity: 0.7;
		}
	}
	@media screen and (max-width: 768px) {
		.slick-arrow {
			width: 20px;
			height: 35px;
		}
	}
}
/* main partners */

/* main news */
.news {
	.container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-top: 0px;
		&:after, &:before {
			display: none;
		}
	}
	h2 {
		width: 100%;
	}
	.news__col {
		width: calc(50% - 30px);
		.news__col__img {
			height: 250px;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
				transition: 300ms;
			}
		}
		.news__col__block {
			background-color: #fff;
			position: relative;
			padding: 15px 20px;
			margin: -70px 30px 0;
			z-index: 1;
			.date {
				display: block;
				color: #8c8c8c;
				margin-bottom: 0px;
			}
			h5 {
				font-family: $bold;
				margin: 15px 0;
				transition: 300ms;
			}
		}
		&:hover {
			text-decoration: none;
			color: #454545;
			.news__col__img img {
				transform: scale(1.1);
			}
			.news__col__block h5 {
				color: #d7b56d;
			}
		}
	}
	@media screen and (max-width: 1199px) {
		.news__col {
			.news__col__img {
				height: 200px;
			}
		}
	}
	@media screen and (max-width: 991px) {
		.news__col {
			width: calc(50% - 15px);
			.news__col__block {
				padding: 15px 0;
				margin: 0;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.news__col h5 {
			color: #d7b56d;
		}
	}
	@media screen and (max-width: 460px) {
		.news__col {
			width: 100%;
			.news__col__img {
				height: auto;
				img {
					height: auto;
				}
			}
		}
	}
}
/* main news */

/* main content */