/* footer */
footer {
	border-top: 3px solid #d7b56d;
	padding-top: 65px;
	font-size: 18px;
	ul {
		@include ul-default;
	}
	a {
		font-size: 18px;
	}
	.up {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		&:after, &:before {
			display: none;
		}
	}
	.footer__col {
		padding-right: 15px;
		margin-bottom: 50px;
		&.large {
			width: 35%;
			padding-right: 35px;
		}
		&.small {
			width: 25%;
			font-size: 18px;
		}
		h5 {
			font-family: $bold;
			text-transform: uppercase;
			margin: 0 0 20px;
		}
		.logo {
			display: table;
			margin-bottom: 15px;
		}
		ul {
			li {
				margin: 13px 0;
				a {
					&:hover {
						text-decoration: none;
					}
				}
				&.active a {
					color: #d7b56d;
				}
			}
		}
		.footer__news {
			li {
				display: block;
				margin: 0 0 33px;
				a {
					display: flex;
					font-size: 16px;
				}
				.footer__news--img {
					width: 80px;
					height: 80px;
					img {
						width: 100%;
						height: 100%;
						object-position: center center;
						object-fit: cover;
					}
				}
				.footer__news--info {
					width: calc(100% - 100px);
					padding-left: 17px;
					.date {
						display: block;
						color: #8c8c8c;
						margin-bottom: 5px;
					}
				}

			}
		}
		.footer__cts {
			li {
				display: flex;
				align-items: flex-start;
				flex-wrap: nowrap;
				margin: 0 0 25px;
				.footer__cts--left {
					white-space: nowrap;
				}
				.footer__cts--right {
					a {
						display: table;
					}
				}
			}
		}
		.socials {
			li {
				margin: 0 45px 0 0;
			}
		}
	}
	.down {
		display: flex;
		width: 1140px;
		flex-wrap: wrap;
		justify-content: space-between;
		font-size: 16px;
		padding: 35px 0;
		border-top: 1px solid #d7b56d;
		&:before, &:after {
			display: none;
		}
		p {
			@include inline-block;
			margin: 0;
		}
		.artmedia {
			position: relative;
			font-size: 16px;
			padding-right: 130px;
			img {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
			&:hover {
				text-decoration: none;
			}
		}
	}
	@media screen and (max-width: 1199px) {
		font-size: 16px;
		padding-top: 30px;
		ul li {
			a {
				font-size: 16px;
			}
		}
		.footer__col {
			margin-bottom: 20px;
			&.small {
				font-size: 16px;
			}
			ul li {
				margin: 10px 0;
			}
			.footer__news li a {
				font-size: 14px;
			}
		}
		.down {
			width: 940px;
			padding: 20px 0;
		}
	}
	@media screen and (max-width: 991px) {
		font-size: 14px;
		ul li a {
			font-size: 14px;
		}
		.footer__col {
			width: 50%;
			&:nth-of-type(1) {
				order: 1;
			}
			&:nth-of-type(2) {
				order: 2;
			}
			&:nth-of-type(3) {
				order: 3;
			}
			&:nth-of-type(4) {
				order: 5;
			}
			&:nth-of-type(5) {
				order: 4;
			}
			&:nth-of-type(6) {
				order: 6;
			}
			&.large {
				width: 50%;
				padding-right: 15px;
			}
			&.small {
				width: 50%;
				font-size: 14px;
				padding-right: 0;
			}
			&.footer--about, &.footer--cts {
				width: 100%;
				br {
					display: none;
				}
			}
			h5 {
				margin-bottom: 15px;
			}
			ul li {
				margin: 8px 0;
			}
			.socials {
				li {
					margin-right: 30px;
				}
			}
			.footer__news {
				li {
					margin-bottom: 20px;
				}
			}
		}
		.down {
			width: 720px;
			font-size: 14px;
			.artmedia {
				font-size: 14px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		padding-top: 20px;
		.down {
			width: 100%;
			padding: 15px;
			p {
				display: block;
				width: 100%;
				text-align: center;
			}
			.artmedia {
				float: none;
				margin: 10px auto 0;
			}
		}
	}
	@media screen and (max-width: 680px) {
		.footer__col {
			width: 100%;
			&.large {
				width: 100%;
				padding-right: 0;
			}
			&.small {
				width: 100%;
			}
			.footer__news {
				li:last-of-type {
					margin-bottom: 0;
				}
			}
			.footer__cts {
				li {
					margin-bottom: 15px;
				}
			}
			&.footer--nav {
				display: none;
			}
		}
	}
	@media screen and (max-width: 420px) {
		.footer__col {
			.footer__news li {
				.footer__news--img {
					width: 60px;
					height: 60px;
				}
				.footer__news--info {
					width: calc(100% - 60px);
					padding-left: 10px;
				}
			}
		}
	}
}
/* footer */