/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

@font-face {
    font-family: 'CenturyGothicRegular';
    src: url('../fonts/CenturyGothicRegular.eot');
    src: url('../fonts/CenturyGothicRegular.eot') format('embedded-opentype'),
         url('../fonts/CenturyGothicRegular.woff2') format('woff2'),
         url('../fonts/CenturyGothicRegular.woff') format('woff'),
         url('../fonts/CenturyGothicRegular.ttf') format('truetype'),
         url('../fonts/CenturyGothicRegular.svg#CenturyGothicRegular') format('svg');
}

@font-face {
    font-family: 'CenturyGothicBold';
    src: url('../fonts/CenturyGothicBold.eot');
    src: url('../fonts/CenturyGothicBold.eot') format('embedded-opentype'),
         url('../fonts/CenturyGothicBold.woff2') format('woff2'),
         url('../fonts/CenturyGothicBold.woff') format('woff'),
         url('../fonts/CenturyGothicBold.ttf') format('truetype'),
         url('../fonts/CenturyGothicBold.svg#CenturyGothicBold') format('svg');
}


$regular: 'CenturyGothicRegular';
$bold: 'CenturyGothicBold';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	color: #414141;
	padding-top: 104px;
	margin: 0;
	line-height: 1.3;
	@media screen and (max-width: 1280px) {
		line-height: 1.2;
		padding-top: 80px;
	}
}

h1 {
	font-family: $regular;
	font-size: 42px;
	text-transform: uppercase;
	text-align: center;
	color: #414141;
	margin: 0 0 40px;
	@media screen and (max-width: 1199px) {
		font-size: 36px;
		margin-bottom: 30px;
	}
	@media screen and (max-width: 991px) {
		font-size: 28px;
		margin-bottom: 20px;
	}
	@media screen and (max-width: 768px) {
		font-size: 24px;
		margin-bottom: 15px;
	}
}
h2 {
	font-family: $regular;
	font-size: 38px;
	color: #414141;
	text-transform: uppercase;
    margin: 0 0 40px;
	a {
		font-family: $regular;
		font-size: 38px!important;
		&:hover {
			text-decoration: none;
		}
	}
	@media screen and (max-width: 1199px) {
		font-size: 30px;
		margin-bottom: 30px;
		a {
			font-size: 30px!important;
		}
	}
	@media screen and (max-width: 991px) {
		font-size: 26px;
		margin-bottom: 20px;
		a {
			font-size: 26px!important;
		}
	}
	@media screen and (max-width: 768px) {
		font-size: 22px;
		margin-bottom: 15px;
		a {
			font-size: 22px!important;
		}
	}
}
h3 {
	font-family: $regular;
	font-size: 24px;
	color: #414141;
	@media screen and (max-width: 1199px) {
		font-size: 22px;
	}
	@media screen and (max-width: 991px) {
		font-size: 20px;
	}
	@media screen and (max-width: 768px) {
		font-size: 18px;
	}
}
h4 {
	font-family: $regular;
	font-size: 22px;
	color: #414141;
	@media screen and (max-width: 1199px) {
		font-size: 20px;
	}
	@media screen and (max-width: 991px) {
		font-size: 18px;
	}
}
h5 {
	font-family: $regular;
	font-size: 18px;
	color: #414141;
	@media screen and (max-width: 991px) {
		font-size: 16px;
	}
}
h6 {
	font-family: $regular;
	font-size: 16px;
	text-transform: uppercase;
	color: #414141;
}
a {
	color: #414141;
	transition: 350ms;
	text-decoration: none;
	&:focus {
		color: #414141;
		text-decoration: none;
	}
	&:hover {
		text-decoration: underline;
		color: #d7b56d;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"] {
	border-radius: 0px;
	-webkit-appearance: none;
	box-shadow: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
	font-size: 18px;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
    	width: 100%;
    	min-height: 55px;
		background-color: #fff;
		font-family: $regular;
		font-size: 18px;
		color: #414141;
		padding: 14px 15px;
		border: 1px solid #d7b56d;
		margin-bottom: 20px;
		&::placeholder {
			color: #7b7b7b;
		}
	}
	p {
		margin-bottom: 15px;
	}
	@media screen and (max-width: 991px) {
		font-size: 16px;
		textarea, 
	    input[type="text"], 
	    input[type="email"], 
	    input[type="search"], 
	    input[type="password"] {
	    	min-height: 45px;
	    	font-size: 16px;
	    	padding: 12px 15px;
	    	margin-bottom: 15px;
	    }
	}
}
.main {
	.container {
		padding-top: 90px;
		padding-bottom: 90px;
		@media screen and (max-width: 1199px) {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		@media screen and (max-width: 991px) {
			padding-top: 35px;
			padding-bottom: 35px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}
	h1, h2 {
		text-align: center;
	}
}
.btn {
	display: table;
	height: 60px;
	width: auto!important;
	background-color: #d7b56d!important;
	font-family: $regular!important;
	font-size: 18px;
	color: #fff!important;
	cursor: pointer;
	border-radius: 0px;
	border: 2px solid #d7b56d;
	padding: 15px 45px!important;
	line-height: 1;
	transition: 300ms;
	&:focus, &:active {
		background-color: #d7b56d!important;
		color: #fff!important;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #d7b56d!important;
	}	
	@media screen and (max-width: 1199px) {
		height: 50px;
		padding: 11px 35px!important;
	}
	@media screen and (max-width: 991px) {
		height: 45px;
		padding: 9px 30px!important;
	}
}

.more {
	height: 50px;
	padding: 15px 40px;
	border: 1px solid #d7b56d;
	line-height: 1;
	&:hover {
		background-color: #d7b56d;
		text-decoration: none;
		color: #fff;
	}
	@media screen and (max-width: 680px) {
		height: 40px;
		padding: 10px 30px;
	}
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		font-size: 16px;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			left: 0;
			top: 0px;
		}
	}
}

.raiting--list {
	@include ul-default;
	li {
		position: relative;
		padding-left: 28px;
		margin: 20px 0;
		span {
			display: flex;
			width: 25px;
			justify-content: flex-end;
			font-family: $bold;
			font-size: 20px;
			color: #d7b56d;
			position: absolute;
			left: 0;
			top: 0;
			line-height: 1;
			text-align: right;
		}
	}
	&.img--list {
		li {
			padding-left: 65px;
			span {
				width: auto;
				padding: 0 15px;
				left: 0;
				&:before, &:after {
					content: '';
					width: 13px;
					height: 25px;
					background: url(../img/icon-1.png);
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}
				&:before {
					left: 0;
				}
				&:after {
					transform: scaleX(-1) translateY(-50%);
					right: 0;
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		li {
			margin: 15px 0;
			span {
				font-size: 18px;
			}
		}
		&.img--list li {
			padding-left: 60px;
		}
	}
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 25px;
	font-size: 16px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #304fbf;
		position: absolute;
		top: -2px;
		left: 0;
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email:after {
	content: '\f0e0';
	font-size: 16px;
	top: 2px;
}

.close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 25px;
        height: 2px;
        background-color: #d7b56d;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
    	opacity: 1;
    	&:before {
    		transform: rotate(135deg);
    	}
    	&:after {
    		transform: rotate(45deg);
    	}
    }
}
.socials {
	@include ul-default;
	display: flex;
	flex-wrap: wrap;
	li {
		margin-right: 35px;
		a {
			font-size: 28px;
		}
	}
	@media screen and (max-width: 991px) {
		li {
			margin-right: 25px;
			a {
				font-size: 24px;
			}
		}
	}
}

.italic {
    font-style: italic;
}

.brown {
	color: #d7b56d;
}

/* header */
header {
	height: 104px;
	background-color: #fff;
	text-align: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	border-bottom: 3px solid #d7b56d;
	padding: 20px 40px;
	line-height: 1;
	z-index: 9;
	ul {
		@include ul-default;
	}
	a:hover {
		text-decoration: none;
	}

	/* header phones */
	.phones {
		display: flex;
		flex-wrap: wrap;
		font-size: 20px;
		position: absolute;
		right: 190px;
		top: 50%;
		transform: translateY(-50%);
		ul li {
			a {
				display: block;
				font-size: 20px;
			}
		}
	}
	/* header phones */

	/* header logo */
	.logo {
		@include inline-block;
		width: 200px;
		position: absolute;
		left: 40px;
		top: 20px;
		img {
			display: block;
			max-width: 100%;
		}
	}
	/* header logo */

	/* header nav */
	.nav {
		left: 0;
		right: 0;
		margin: 24px auto 0;
		> ul > li {
			@include inline-block;
			margin: 0 17px;
			> a {
				font-size: 18px;
				text-transform: uppercase;
			}
			&.active > a {
				color: #d7b56d;
			}
		}
	}
	/* header nav */

	/* header langs */
	.langs {
		position: absolute;
		left: 295px;
		top: 50%;
		transform: translateY(-50%);
		ul {
			display: flex;
			flex-wrap: wrap;
			li {
				position: relative;
				margin: 0 12.5px;
				&:after {
					content: '';
					height: 17px;
					width: 2px;
					background-color: #454545;
					position: absolute;
					right: -12px;
					top: 1px;
				}
				&:last-of-type:after {
					display: none;
				}
				a {
					display: block;
					font-size: 18px;
					text-transform: uppercase;
				}
				&.active a {
					color: #d7b56d;
				}
			}
		}
	}
	/* header langs */

	/* header socials */
	.socials {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}
	/* header spcials */
	
	@media screen and (max-width: 1700px) {
		.nav > ul > li {
			margin: 0 12px;
		}
		.langs {
			left: 260px;
		}
		.phones {
			right: 150px;
		}
	}

	@media screen and (max-width: 1680px) {
		padding: 20px 20px;
		.logo {
			left: 20px;
		}
		.nav > ul > li {
			margin: 0 10px;
			> a {
				font-size: 16px;
			}
		}
		.langs {
			left: 230px;
			ul li {
				&:after {
					top: 0;
				}
				a {
					font-size: 16px;
				}
			}
		}
		.phones {
			font-size: 18px;
			ul li a {
				font-size: 18px;
			}
		}
	}

	@media screen and (max-width: 1440px) {
		.logo {
			width: 160px;
			top: 50%;
			transform: translateY(-50%);
		}
		.langs {
			left: 190px;
		}
		.socials {
			li {
				margin-right: 20px;
				a {
					font-size: 24px;
				}
			}
		}
		.phones {
			right: 110px;
		}
		.nav > ul > li {
			margin: 0 5px;
		}
	}
	
	@media screen and (max-width: 1280px) {
		height: 80px;
		padding: 10px;
		.phones {
			display: none;
			background-color: #d7b56d;
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			z-index: 1;
			padding: 15px;
			transform: translateY(0);
			> span {
				display: none;
			}
			ul li {
				display: table;
				margin: 5px auto;
				a {
					font-family: $bold;
					color: #414141;
				}
			}
		}
		.phones--btn {
			display: block;
			font-family: 'fontAwesome';
			font-size: 24px;
			color: #414141;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
			&:after {
				content: '\f095';
			}
			&:hover {
				text-decoration: none;
			}
		}
		.socials {
			right: 50px;
			li {
				margin: 0 0 0 15px;
			}
		}
		.langs {
			left: 50px;
		}
		.nav {
			display: none;
			max-height: calc(100% - 80px);
			background-color: #fff;
			position: fixed;
			top: 80px;
			left: 0;
			right: 0;
			padding: 10px 0;
			border-bottom: 3px solid #d7b56d;
			overflow: auto;
			margin: 0;
			z-index: 1;
			> ul > li {
				display: block;
				margin: 10px 0;
				> a {
					font-size: 18px;
				}
			}
		}
		.logo {
			left: 0;
			right: 0;
			margin: 0 auto;
		}
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 10px;
			top: 50%;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			transform: translateY(-50%);
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #414141;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
	@media screen and (max-width: 991px) {
		.nav > ul > li > a {
			font-size: 16px;
		}
	}
    @media screen and (max-width: 768px) {
        .phones {
            ul li a {
                pointer-events: auto;
            }
        }
    }
	@media screen and (max-width: 480px) {
		.langs {
			left: 10px;
			top: 8px;
			transform: translateY(0);
			ul li {
				margin: 0 12px 0 0;
				&:after {
					height: 15px;
					width: 1px;
					right: -6px;
				}
				a {
					font-size: 14px;
				}
			}
		}
		.socials {
			li a {
				font-size: 20px;
			}
		}
		.nav {
			padding: 5px 0;
		}
		.nav--btn {
			top: 30px;
			left: 8px;
			transform: translateY(0);
		}
		.phones--btn {
			font-size: 20px;
			right: auto;
			left: 52px;
			top: 35px;
			transform: translateY(0);
		}
		.socials {
			right: 10px;
		}
		.logo {
			width: 150px;
		}
	}
}
/* header */