/* page content */
.page {
    padding-bottom: 80px;
	.news1, .news {
		.date {
			color: #d7b56d;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #d7b56d;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #d7b56d;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #d7b56d;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    h2 {
        margin-top: 55px;
    }
    .nick {
        font-family: $bold;
        cursor: pointer;
        transition: 300ms;
        margin: 5px 0;
        &:hover {
            color: #d7b56d;
        }
    }
    .advices-list {
        padding: 0;
        list-style: none;
        margin: 0 auto;
        .name {
            margin-bottom: 10px;
            a {
                color: #d7b56d;
            }
        }
        .advices__title {
            display: block;
            font-family: $bold;
            padding-top: 5px;
            margin: 0 0 5px;
        }
    }
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
    @media screen and (max-width: 991px) {
        padding-bottom: 50px;
        h2 {
            margin-top: 35px;
        }
    }
    @media screen and (max-width: 768px) {
        padding-bottom: 25px;
        h2 {
            margin-top: 20px;
        }
    }
	@media screen and (max-width: 480px) {
        h2 {
            margin-top: 10px;
        }
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 16px;
    margin: 10px 0 50px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #d7b56d;
			@include inline-block;
			a {
                color: #454545;
				text-decoration: none;
                &:hover {
                    color: #d7b56d;
                }
			}
			&:after {
				content: '/';
				margin: 2px 5px 0px 5px;
                font-family: $bold;
				color: #454545;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
    @media screen and (max-width: 991px) {
        font-size: 14px;
        margin-bottom: 30px;
        ul li {
            &:after {
                margin: 1px 3px 0 3px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
    }
}
/* breadcrumbs */

/* page about */
.page .about {
    background: url(../img/img-28.png) no-repeat right top 15%;
    h2 {
        text-align: center;
        margin: 75px 0 30px;
    }
    @media screen and (max-width: 991px) {
        background: none;
        h2 {
            margin: 50px 0 30px;
        }
    }
    @media screen and (max-width: 768px) {
        h2 {
            margin: 30px 0 20px;
        }
    }
}
.apricot {
    background-color: #f9f4e9;
}
.slogan {
    display: table;
    font-size: 28px;
    font-style: italic;
    padding: 0 110px;
    position: relative;
    margin: 85px auto 25px;
    &:before, &:after {
        content: '';
        width: 93px;
        height: 68px;
        background: url(../img/icon-2.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        top: -5px;
    }
    &:before {
        left: 0;
    }
    &:after {
        right: 0;
        top: 5px;
        transform: rotate(-180deg) translateY(50%);
    }
    @media screen and (max-width: 1199px) {
        font-size: 24px;
        padding: 0 75px;
        &:before, &:after {
            width: 60px;
            height: 40px;
        }
    }
    @media screen and (max-width: 991px) {
        font-size: 20px;
        padding: 0 60px;
        &:before, &:after {
            width: 50px;
            height: 30px;
        }
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
        padding: 0 50px;
        margin-top: 50px;
        &:before, &:after {
            width: 35px;
            height: 20px;
            top: 0;
        }
        &:before {
            left: 10px;
        }
        &:after {
            right: 10px;
            transform: rotate(-180deg) translateY(0);
        }
    }
}
/* page about */

/* page services */
.page {
    .services {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .italic {
        font-size: 16px;
    }
}

/* page services */

/* page articles */
.articles {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .articles__col {
        width: 66.66%;
        margin-left: 0;
        &.left {
            width: 320px;
            padding: 35px 30px 20px;
            margin-left: 0;
            .articles__block {
                margin-bottom: 20px;
            }
        }
        &.right {
            /* width: calc(33.33% - 35px); */
            /* margin-left: 35px; */
            width: 100%;
            .articles__block {
                display: block;
                min-height: 100px;
                position: relative;
                padding-left: 165px;
                margin-bottom: 40px;
                .articles__block--img {
                    width: 150px;
                    height: 100px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center center;
                    }
                }
                h5 {
                    font-size: 16px;
                    margin-top: 0;
                }
            }
        }
        > h5 {
            text-transform: uppercase;
            margin: 0 0 18px;
        }
    }
    .articles__block {
        display: block;
        font-size: 16px;
        margin-bottom: 40px;
        .articles__block--img {
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                transition: 300ms;
            }
        }
        h5 {
            margin: 15px 0 0 0;
            transition: 300ms;
        }
        .date {
            display: block;
            color: #777777;
            margin: 10px 0;
        }
        &:hover {
            text-decoration: none;
            color: #454545;
            .articles__block--img img {
                transform: scale(1.1);
            }
            h5 {
                color: #d7b56d;
            }
        }
    }
    .articles__col--list {
        @include ul-default;
        padding-top: 25px;
        margin-top: 25px;
        border-top: 1px solid #d7b56d;
        li {
            text-transform: uppercase;
            margin-bottom: 10px;
            a {
                text-transform: initial;
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .articles__col {
            width: 66.66%;
            margin-left: 0;
            &.left {
                width: calc(30% - 15px);
                padding: 20px 15px;
                margin-right: 15px;
            }
            &.right {
                .articles__block {
                    min-height: 75px;
                    padding-left: 110px;
                    margin-bottom: 30px;
                    .articles__block--img {
                        width: 100px;
                        height: 75px;
                    }
                }
            }
            .articles__block h5 {
                color: #d7b56d;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .articles__col {
            width: 100%;
            order: 1;
            &.left {
                width: calc(50% - 15px);
                order: 3;
                margin: 0 0 0 15px;
            }
            &.right {
                /* width: calc(50% - 15px); */
                order: 2;
                margin: 0px 15px 0 0;
            }
        }
    }
    @media screen and (max-width: 680px) {
        .articles__col {
            &.left {
                width: 100%;
                margin: 0;
            }
            &.right {
                width: 100%;
                margin: 0;
                .articles__block {
                    min-height: 80px;
                    padding-left: 90px;
                    margin-bottom: 20px;
                    .articles__block--img {
                        width: 80px;
                        height: 60px;
                    }
                }
            }
        }
    }
}
/* page articles */

/* pagination */
.pagination {
    width: 100%;
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid #d7b56d;
    line-height: 1;
    margin: 70px 0 0;
    ul {
        @include ul-default;
        li {
            @include inline-block;
            margin: 5px;
            a {
                font-size: 20px;
                &:hover {
                    text-decoration: none;
                }
            }
            &.active a {
                color: #d7b56d;
            }
            &.prev, &.next {
                margin-top: 10px;
                margin-bottom: 0;
                a {
                    display: block;
                    width: 32px;
                    height: 10px;
                    background: url(../img/pag--left.png) no-repeat center center;
                    background-size: contain;
                    transition: 300ms;
                    &:hover {
                        background: url(../img/pag--leftY.png) no-repeat center center;
                        background-size: contain;
                    }
                }
            }
            &.prev {
                margin-right: 10px;
            }
            &.next {
                margin-left: 10px;
                a {
                    transform: rotate(-180deg);
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        margin: 50px 0 0;
    }
    @media screen and (max-width: 768px) {
        ul li {
            a {
                font-size: 16px;
            }
            &.prev, &.next {
                margin-top: 8px;
            }
        }
    }
}
/* pagination */

/* page contacts */
.cts {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #d7b56d;
    margin-bottom: 85px;
    .cts__col {
        width: 33.33%;
        height: 185px;
        text-align: center;
        position: relative;
        padding: 95px 15px 10px;
        border-right: 1px solid #d7b56d;
        &:last-of-type {
            border-right: none;
        }
        .icon {
            width: 75px;
            height: 53px;
            position: absolute;
            left: 0;
            right: 0;
            top: 27px;
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }
        p {
            margin-bottom: 0;
        }
        ul {
            @include ul-default;
            li {
                display: table;
                margin: 2px auto;
                a {
                    display: block;
                }
            }
        }
        .socials {
            justify-content: center;
            margin-top: 10px;
            li {
                margin: 0 20px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        margin-bottom: 35px;
        .cts__col {
            padding-top: 85px;
            .icon {
                width: 50px;
                height: 40px;
                top: 15px;
            }
        }
    }
    @media screen and (max-width: 680px) {
        .cts__col {
            height: auto;
            width: 100%;
            padding-top: 60px;
            padding-bottom: 15px;
            border-right: none;
            border-bottom: 1px solid #d7b56d;
            &:last-of-type {
                border-bottom: none;
            }
            .icon {
                top: 10px;
            }
        }
    }
}
.cts--info {
    display: flex;
    flex-wrap: wrap;
    .cts--info__col {
        width: 50%;
        .block {
            width: 570px;
        }
        &:nth-of-type(2) {
            .block {
                padding-left: 40px;
            }
        }
    }
    .map {
        .block {
            float: right;
        }
        h2 {
            text-align: left;
        }
        > ymaps, .ymaps-2-1-72-map {
            height: 500px!important;
            float: right;
        }
    }
    h2 {
        font-size: 30px;
        text-transform: initial;
        margin-top: 0;
    }
    .form {
        textarea {
            margin-bottom: 50px;
        }
        .g-recaptcha {
            @include inline-block;
        }
        .btn {
            @include inline-block;
            float: right;
        }
    }
    @media screen and (max-width: 1199px) {
        .cts--info__col {
            .block {
                width: 100%;
                padding-left: 15px!important;
                padding-right: 15px;
            }
        }
    }
    @media screen and (max-width: 991px) {
        .form {
            .g-recaptcha {
                float: right;
            }
            textarea {
                margin-bottom: 25px;
            }
            .btn {
                margin-top: 15px;
            }
        }
        h2 {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        .cts--info__col {
            width: 100%;
        }
        h2 {
            text-align: center;
        }
        .map {
            margin-bottom: 35px;
            h2 {
                text-align: center;
            }
            > ymaps, .ymaps-2-1-72-map {
                height: 250px!important;
                float: right;
            }
        }
        .form {
            .g-recaptcha {
                float: none;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .form {
            .g-recaptcha {
                float: right;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .form .g-recaptcha {
            transform: scale(0.9);
            transform-origin: 100% 50%;
        }
    }
}
/* page contacts */

/* modal */
.modal {
    .modal-dialog {
        top: 45%;
        margin: 0 auto;
        transform: translateY(-50%)!important;
    }
    .modal-content {
        width: auto;
        max-width: 420px;
        border-radius: 0px;
        margin: 0 auto;
        h4 {
            font-family: $bold;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
            padding: 0 15px;
            margin: 0;
        }
    }
    form {
        display: block;
        padding-bottom: 140px;
        margin-top: 25px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            width: 100%;
            min-height: 55px;
            background-color: #fff;
            font-family: $regular;
            font-size: 18px;
            color: #414141;
            padding: 14px 15px;
            border: 1px solid #d7b56d;
            margin-bottom: 20px;
            &::placeholder {
                color: #7b7b7b;
            }
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            height: 50px;
            float: right;
            padding: 14px 45px!important;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 991px) {
        .modal-content {
            form {
                textarea, 
                input[type="text"], 
                input[type="email"], 
                input[type="search"], 
                input[type="password"] {
                    min-height: 45px;
                    font-size: 16px;
                    padding: 12px 15px;
                    margin-bottom: 15px;
                }
                .btn {
                    height: 40px;
                    padding: 9px 30px!important;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .modal-content {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 420px) {
        .modal-dialog {
            margin: 0 10px;
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .modal-content {
            form {
                .g-recaptcha {
                    transform: scale(0.85);
                    transform-origin: 100% 50%;
                }
            }
        }
    }
}

.call--btn {
    position: fixed;
    bottom: 100px;
    right: 105px;
    display: table;
    background-color: #d7b56d;
    font-family: 'FontAwesome';
    border-radius: 0px;
    text-decoration: none!important;
    width: 70px;
    height: 70px;
    text-align: center;
    padding: 16px 5px 5px 5px;
    box-sizing: border-box;
    outline: none!important;
    z-index: 1;
    i {
        display: block;
        font-size: 38px;
        color: #fff;
        transition: 300ms;
    }
    &:after, &:before  {
        content: '';
        width: 90%;
        height: 90%;
        background-color: transparent;
        border: 1px solid #d7b56d;
        position: absolute;
        top: 5%;
        left: 5%;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
        animation-name: ring;
        outline: none;  
    }
    &:after {
        animation-delay: 0.6s;
    }
    &:before {
        animation-delay: 0.8s;
    }
    &:hover i {
        transform: scale(1.1);
    }
    @media screen and (max-width: 1440px) {
        right: 50px;
        bottom: 50px;
    }
    @media screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
        padding-top: 13px;
        right: 15px;
        i {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        width: 40px;
        height: 40px;
        padding-top: 10px;
    }
}
@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.1;
    transform: scale(1.7);
  }
}

/* modal */

/* page content */